/* eslint-disable import/prefer-default-export */
import { useState } from 'react';

import resumeActionLinkButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/resume_list/resume_action_link_button_click';
import { Action, BottomSheetFooter, Button, BottomSheet } from '@hh.ru/magritte-ui';
import {
    EllipsisHorizontalOutlinedSize24,
    TrashOutlinedSize24,
    CopyOutlinedSize24,
    EyeOutlinedSize24,
    PenOutlinedSize24,
} from '@hh.ru/magritte-ui/icon';

import translation from 'src/components/translation';
import { ApplicantResumeItem } from 'src/models/applicantResumes';
import { DuplicateAction } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/components/ResumeActions/DuplicateAction';
import { EditAction } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/components/ResumeActions/EditAction';
import { VisibilityAction } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/components/ResumeActions/VisibilityAction';
import { useResumeDeleteModalContext } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/context/resumeDeleteModal';
import { BLOCKED, NOT_FINISHED } from 'src/utils/constants/resumeStatuses';

import styles from './styles.less';

const TrlKeys = {
    edit: 'resume.edit',
    close: 'resumeList.resumeCard.actionMenu.close',
    delete: 'resumeList.actionline.delete',
};

type Props = {
    resume: ApplicantResumeItem;
};

export const MobileActionsMenu = translation<Props>(({ resume, trls }) => {
    const deleteModalContext = useResumeDeleteModalContext();
    const {
        _attributes: { hash, isSearchable, status, id },
    } = resume;

    const resumeId = parseInt(id, 10);
    const [visible, setVisible] = useState(false);

    const editUrl = isSearchable || status === BLOCKED ? `/resume/${hash}` : `/applicant/resumes/short?resume=${hash}`;
    const isBlocked = status === BLOCKED;
    const isNotFinished = status === NOT_FINISHED;

    if (isBlocked) {
        return (
            <div className={styles.menuMobile}>
                <Action
                    icon={TrashOutlinedSize24}
                    mode="secondary"
                    style="neutral"
                    data-qa="resume-delete"
                    showBackground={false}
                    disablePadding={false}
                    onClick={() => {
                        deleteModalContext.openModal({ hash, from: 'resume-list' });
                        resumeActionLinkButtonClick({ buttonName: 'resume_delete', resumeId });
                    }}
                />
            </div>
        );
    }

    if (isNotFinished && isSearchable) {
        return null;
    }

    return (
        <div className={styles.menuMobile}>
            <Action
                icon={EllipsisHorizontalOutlinedSize24}
                mode="secondary"
                style="neutral"
                showBackground={false}
                disablePadding={false}
                onClick={() => {
                    setVisible(!visible);
                }}
            />
            <BottomSheet
                visible={visible}
                onClose={() => {
                    setVisible(false);
                }}
                height="content"
                footer={
                    <BottomSheetFooter>
                        <Button
                            stretched
                            mode="secondary"
                            style="neutral"
                            size="medium"
                            onClick={() => {
                                setVisible(false);
                            }}
                        >
                            {trls[TrlKeys.close]}
                        </Button>
                    </BottomSheetFooter>
                }
            >
                <ul role="menu" aria-orientation="vertical" className={styles.menu}>
                    {!isSearchable ? (
                        <li role="menuitem" className={styles.item}>
                            <Button
                                stretched
                                icon={<TrashOutlinedSize24 />}
                                size="large"
                                style="negative"
                                mode="tertiary"
                                data-qa="resume-delete"
                                onClick={() => {
                                    deleteModalContext.openModal({ hash, from: 'resume-list' });
                                    setVisible(!visible);
                                    resumeActionLinkButtonClick({
                                        buttonName: 'resume_delete',
                                        resumeId,
                                    });
                                }}
                            >
                                {trls[TrlKeys.delete]}
                            </Button>
                        </li>
                    ) : (
                        <>
                            <li role="menuitem" className={styles.item}>
                                <VisibilityAction
                                    stretched
                                    size="large"
                                    icon={<EyeOutlinedSize24 />}
                                    hash={hash}
                                    style="neutral"
                                    resumeId={resumeId}
                                />
                            </li>
                            <li role="menuitem" className={styles.item}>
                                <EditAction
                                    stretched
                                    size="large"
                                    icon={<PenOutlinedSize24 />}
                                    hash={hash}
                                    style="neutral"
                                    href={editUrl}
                                    resumeId={resumeId}
                                />
                            </li>
                            <li role="menuitem" className={styles.item}>
                                <DuplicateAction
                                    stretched
                                    size="large"
                                    hash={hash}
                                    icon={<CopyOutlinedSize24 />}
                                    style="neutral"
                                    resumeId={resumeId}
                                />
                            </li>
                        </>
                    )}
                </ul>
            </BottomSheet>
        </div>
    );
});
