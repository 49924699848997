/* eslint-disable import/prefer-default-export */
import { Link } from '@hh.ru/magritte-ui';
import isValidKey from 'bloko/common/isValidKey';
import { format, formatToReactComponent } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { RecommendationDetails } from 'src/models/applicantResumesStatistics';
import { Recommendations } from 'src/models/resume/resumeCommon.types';
import {
    useRecommendationLink,
    RECOMMENDATIONS_LINKS_MAP,
} from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/hooks/useRecommendationLink';

const TrlKeys = {
    respond: 'resumeList.recommendations.text.response',
    editResume: 'resumeList.recommendations.text.edit',
    [`editResume.withLink`]: 'resumeList.recommendations.text.edit.applicantRecommendations',
    [`editResume.withLink.Link`]: 'resumeList.recommendations.text.edit.applicantRecommendations.link',
    updateResume: 'resumeList.recommendations.text.update',
    [`updateResume.withLink`]: 'resumeList.recommendations.text.update.applicantRecommendations',
    [`updateResume.withLink.Link`]: 'resumeList.recommendations.text.update.applicantRecommendations.link',
    changeVisibility: 'resumeList.recommendations.text.visibility',
    [`changeVisibility.withLink`]: 'resumeList.recommendations.text.visibility.applicantRecommendations',
    [`changeVisibility.withLink.Link`]: 'resumeList.recommendations.text.visibility.applicantRecommendations.link',
    correctResume: 'resumeList.recommendations.text.blocked',
    onModeration: 'resumeList.recommendations.text.onModeration',
    needVerifiedPhone: 'resumeList.recommendations.text.verification',
    needSkillsSurveyBase: 'resumeList.recommendations.text.skillsSurvey.base',
};

type Props = {
    recommendation?: Recommendations;
    recommendationDetails?: RecommendationDetails;
};

export const ResumeRecommendationText = translation<Props>(({ trls, recommendation, recommendationDetails }) => {
    if (!recommendation) {
        return null;
    }

    const recommendationLink = useRecommendationLink(recommendation);

    if (isValidKey(RECOMMENDATIONS_LINKS_MAP, recommendation) && recommendationLink) {
        return (
            <>
                {formatToReactComponent(trls[TrlKeys[`${recommendation}.withLink`]], {
                    '{0}': (
                        <Link inline href={recommendationLink} typography="custom-3-medium">
                            {trls[TrlKeys[`${recommendation}.withLink.Link`]]}
                        </Link>
                    ),
                })}
            </>
        );
    }

    if (recommendation === Recommendations.NeedSkillSurvey) {
        return (
            <>
                {format(trls[TrlKeys.needSkillsSurveyBase], {
                    '{0}': recommendationDetails?.translations?.resumeRecommendationTextProfession || '',
                })}
            </>
        );
    }

    return <>{trls[TrlKeys[recommendation]]}</>;
});
