import PropTypes from 'prop-types';

// копия типов src/models/resume/resumeCommon.types.ts#L242
export enum ResumeRecommendationVariants {
    /** хорошее резюме, откликайтесь */
    Respond = 'respond',
    /** дополните резюме */
    EditResume = 'editResume',
    /** обновите резюме */
    UpdateResume = 'updateResume',
    /** смените видимость */
    ChangeVisibility = 'changeVisibility',
    /** исправьте заблокированное резюме */
    CorrectResume = 'correctResume',
    /** модератор проверяет ваше резюме */
    OnModeration = 'onModeration',
    /** верифицируйте телефон в резюме */
    NeedVerifiedPhone = 'needVerifiedPhone',
    /** пройдите опросник по навыкам */
    NeedSkillSurvey = 'needSkillsSurvey',
}

export const resumeRecommendationType = PropTypes.oneOf(Object.values(ResumeRecommendationVariants));
