import {
    ApplicantSkillsVerificationMethod,
    ApplicantSkillsVerificationMethodKeys,
} from 'src/models/applicantSkillsVerificationMethods';

export function getTagName(method: ApplicantSkillsVerificationMethod): string {
    const verificationObject = method[ApplicantSkillsVerificationMethodKeys.VerificationObjects][0];
    if (verificationObject.category === 'SKILL') {
        return verificationObject.name;
    } else if (verificationObject.level !== null) {
        return `${verificationObject.name} ${verificationObject.level.name}`;
    }
    return method.name;
}
