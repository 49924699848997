import { useState, useEffect, useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Analytics from '@hh.ru/analytics-js';
import resumeRenewCompleteExternal from '@hh.ru/analytics-js-events/build/xhh/applicant/resume/resume_renew_complete';
import resumeActionLinkButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/resume_list/resume_action_link_button_click';
import resumeUpdateSuccessScreenShown from '@hh.ru/analytics-js-events/build/xhh/applicant/resume_list/resume_update_success_screen_shown';
import { Link } from '@hh.ru/redux-spa-middleware';
import { H3Section } from 'bloko/blocks/header';
import Text, { TextImportance } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import LocalStorageWrapper from 'bloko/common/storage/LocalStorageWrapper';
import { formatToReactComponent } from 'bloko/common/trl';

import HumanDate from 'src/components/HumanDate';
import translation from 'src/components/translation';
import { useAddEventPixelsRu } from 'src/hooks/useAddEventPixelsRu';
import { useSelector } from 'src/hooks/useSelector';
import { resumesSetToUpdateValueAction } from 'src/models/applicantResumes';
import { BLOCKED, NOT_FINISHED, resumeStatusType, ResumeTags } from 'src/utils/constants/resumeStatuses';
import { humanDatesRulesType } from 'src/utils/humanDatesRules';

import BotUpdateResumeContext from 'src/pages/ApplicantResumes/components/BotUpdateResumeContext';
import BotUpdateResumeModal from 'src/pages/ApplicantResumes/components/BotUpdateResumeModal';
import ResumeActions from 'src/pages/ApplicantResumes/components/ResumeActions';
import ResumeButtons from 'src/pages/ApplicantResumes/components/ResumeButtons';
import ResumeRecommendations from 'src/pages/ApplicantResumes/components/ResumeRecommendations';
import ResumeStatisticsList from 'src/pages/ApplicantResumes/components/ResumeStatisticsList';
import { toUpdateType } from 'src/pages/ApplicantResumes/components/resumeToUpdate';

const Features = {
    successResumeUpdateModalTimeout: 'success_resume_update_modal_timeout_h',
    showResumeBlockedWhileExpertIsWorking: 'show_resume_blocked_while_expert_is_working',
};

const LAST_SHOW_SUCCESS_UPDATE_MODAL_KEY = 'last_show_success_update_modal';

const Resume = ({ resume, statistics, recommendation, recommendationDetails, setToUpdateValue, trls }) => {
    const { _attributes, title, toUpdate, renewalTime } = resume;
    const [updated, setUpdated] = useState(null);
    const [timeUntilUpdate, setTimeUntilUpdate] = useState(null);
    const [currentTimeLeft, setCurrentTimeLeft] = useState(toUpdate.value * 1000);
    const [isAutoRenewalRecommended, setIsAutoRenewalRecommended] = useState(
        toUpdate.value > 0 && !renewalTime?.nearestIntervalStartTime
    );
    const [successUpdateModalVisible, setSuccessUpdateModalVisible] = useState(false);
    const [successUpdate, setSuccessUpdate] = useState(false);

    const chatBotHref = useSelector((state) => state.chatBot?.href);
    const successUpdateModalTimeout = useSelector((state) => state.features[Features.successResumeUpdateModalTimeout]);
    const showResumeBlockedWhileExpertIsWorking = useSelector(
        (state) => state.features[Features.showResumeBlockedWhileExpertIsWorking]
    );
    const canUpdateResumeByBot = Boolean(chatBotHref);
    const isUnpubslished = [NOT_FINISHED, BLOCKED].includes(_attributes.status);
    const isPreparingByExpert =
        showResumeBlockedWhileExpertIsWorking && _attributes.tags?.includes(ResumeTags.ExpertIsWorking);
    const addEventPixelsRu = useAddEventPixelsRu();

    useEffect(() => {
        setUpdated(_attributes.updated);
        setTimeUntilUpdate(new Date().getTime() + toUpdate.value * 1000);
    }, [_attributes.updated, toUpdate.value]);

    // без useCallback будет неправильно работать TouchModule в ResumeUpdate
    const onUpdate = useCallback(
        (updated) => {
            setUpdated(updated);
            setToUpdateValue({ hash: _attributes.hash, value: _attributes.update_timeout / 1000 });
            setIsAutoRenewalRecommended(!renewalTime?.nearestIntervalStartTime);
            setSuccessUpdate(true);

            resumeRenewCompleteExternal();
            addEventPixelsRu('B2C_RESUME_UPDATE');
        },
        [
            _attributes.hash,
            _attributes.update_timeout,
            addEventPixelsRu,
            renewalTime.nearestIntervalStartTime,
            setToUpdateValue,
        ]
    );

    // без useCallback будет неправильно работать TouchModule в ResumeUpdate
    const onTimeChanged = useCallback((currentTimeLeft) => {
        setCurrentTimeLeft(currentTimeLeft);
        if (currentTimeLeft === 0) {
            setIsAutoRenewalRecommended(false);
        }
    }, []);

    const closeSuccessUpdateModal = useCallback(() => {
        setSuccessUpdateModalVisible(false);

        Analytics.sendEvent('applicant', 'resume_renew_modal', 'close');
    }, []);

    const openSuccessUpdateModal = useCallback(() => {
        setSuccessUpdateModalVisible(true);

        LocalStorageWrapper.setItem(LAST_SHOW_SUCCESS_UPDATE_MODAL_KEY, Date.now().toString());

        Analytics.sendEvent('applicant', 'resume_renew_modal', 'opened');
        resumeUpdateSuccessScreenShown({ resumeId: _attributes.id });
    }, [_attributes.id]);

    const botUpdateResumeContextValue = useMemo(
        () => ({
            modalVisible: successUpdateModalVisible,
            handleCloseModal: closeSuccessUpdateModal,
            resumeHash: _attributes.hash,
            resumeId: _attributes.id,
            successUpdate,
            recommendationDetails,
            chatBotHref,
            canUpdateResumeByBot,
        }),
        [
            closeSuccessUpdateModal,
            successUpdateModalVisible,
            _attributes.hash,
            _attributes.id,
            successUpdate,
            recommendationDetails,
            chatBotHref,
            canUpdateResumeByBot,
        ]
    );

    useEffect(() => {
        const lastShowModal = Number(LocalStorageWrapper.getItem(LAST_SHOW_SUCCESS_UPDATE_MODAL_KEY));
        const now = Date.now();
        const successUpdateModalTimeoutMs = successUpdateModalTimeout * 60 * 60 * 1000;
        const timeoutPassed = now - lastShowModal > successUpdateModalTimeoutMs;

        if (canUpdateResumeByBot && successUpdate && timeoutPassed) {
            openSuccessUpdateModal();
        }
    }, [successUpdate, openSuccessUpdateModal, canUpdateResumeByBot, successUpdateModalTimeout]);

    const handleTitleClick = useCallback(() => {
        resumeActionLinkButtonClick({ buttonName: 'resume_title', resumeId: _attributes.id });
    }, [_attributes.id]);

    const resumeTitle = title[0] ? (
        <span className="b-marker" data-qa="resume-title">
            {title[0].string}
        </span>
    ) : (
        trls[Resume.trls.emptyTitle]
    );

    if (isPreparingByExpert) {
        return (
            <div className="applicant-resumes-card-wrapper noprint">
                <div
                    className="applicant-resumes-card"
                    data-qa="resume"
                    data-qa-id={_attributes.id}
                    data-qa-title={title[0] ? title[0].string : trls[Resume.trls.emptyTitle]}
                >
                    <H3Section>{resumeTitle}</H3Section>
                    <VSpacing base={4} />
                    <Text importance={TextImportance.Secondary}>{trls[Resume.trls.isPreparingByExpert]}</Text>
                </div>
            </div>
        );
    }

    return (
        <div className="applicant-resumes-card-wrapper noprint">
            <div
                className={classnames('applicant-resumes-card', {
                    'b-marker b-marker-resumelist': _attributes.marked,
                })}
                data-qa={classnames('resume', {
                    'resume-highlighted': _attributes.marked,
                })}
                data-qa-id={_attributes.id}
                data-qa-title={title[0] ? title[0].string : trls[Resume.trls.emptyTitle]}
            >
                <H3Section>
                    <Link
                        className={classnames({
                            'applicant-resumes-title_unpublished': isUnpubslished,
                        })}
                        data-qa="resume-title-link"
                        to={
                            _attributes.isSearchable || _attributes.status === BLOCKED
                                ? `/resume/${_attributes.hash}`
                                : `/applicant/resumes/short?resume=${_attributes.hash}`
                        }
                        onClick={handleTitleClick}
                    >
                        {resumeTitle}
                    </Link>
                </H3Section>

                {isUnpubslished ? (
                    <>
                        <VSpacing base={4} />
                        <Text strong>
                            {_attributes.status === NOT_FINISHED && trls[Resume.trls.notFinishedText]}
                            {_attributes.status === BLOCKED && (
                                <span className="applicant-resumes-subtitle-blocked">
                                    {trls[Resume.trls.blockedText]}
                                </span>
                            )}
                        </Text>
                    </>
                ) : (
                    <>
                        <VSpacing base={2} />
                        <div className="applicant-resumes-update">
                            <div className="applicant-resumes-action applicant-resumes-action_second">
                                {currentTimeLeft > 0 && !renewalTime?.nearestIntervalStartTime
                                    ? formatToReactComponent(trls[Resume.trls.updatedManual], {
                                          '{0}': <HumanDate date={timeUntilUpdate} showClock textify preposition />,
                                      })
                                    : formatToReactComponent(trls[Resume.trls.updated], {
                                          '{0}': <HumanDate date={updated} showClock textify preposition />,
                                      })}
                            </div>
                        </div>

                        <ResumeStatisticsList
                            status={_attributes.status}
                            hash={_attributes.hash}
                            statistics={statistics}
                            resumeId={_attributes.id}
                        />
                    </>
                )}

                <BotUpdateResumeContext.Provider value={botUpdateResumeContextValue}>
                    <ResumeButtons
                        resume={resume}
                        onUpdate={onUpdate}
                        recommendation={recommendation}
                        recommendationDetails={recommendationDetails}
                        onTimeChanged={onTimeChanged}
                        isAutoRenewalRecommended={isAutoRenewalRecommended}
                    />

                    <ResumeActions
                        resumeProps={resume}
                        onUpdate={onUpdate}
                        onTimeChanged={onTimeChanged}
                        isAutoRenewalRecommended={isAutoRenewalRecommended}
                    />

                    <ResumeRecommendations
                        resume={resume}
                        recommendation={recommendation}
                        recommendationDetails={recommendationDetails}
                    />

                    <BotUpdateResumeModal />
                </BotUpdateResumeContext.Provider>
            </div>
        </div>
    );
};

Resume.propTypes = {
    resume: PropTypes.shape({
        _attributes: PropTypes.shape({
            hash: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired,
            isSearchable: PropTypes.bool.isRequired,
            marked: PropTypes.bool.isRequired,
            status: resumeStatusType.isRequired,
            update_timeout: PropTypes.number.isRequired, // eslint-disable-line camelcase
            updated: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
            tags: PropTypes.arrayOf(PropTypes.string),
        }).isRequired,
        humanDatesRules: humanDatesRulesType,
        showUpdateBlock: PropTypes.bool.isRequired,
        title: PropTypes.arrayOf(
            PropTypes.shape({
                string: PropTypes.string.isRequired,
            })
        ).isRequired,
        toUpdate: toUpdateType.isRequired,
        renewalTime: PropTypes.object,
    }).isRequired,
    statistics: PropTypes.shape({
        searchShows: PropTypes.object,
        views: PropTypes.object,
        invitations: PropTypes.object,
    }),
    recommendation: PropTypes.string,
    recommendationDetails: PropTypes.object,
    setToUpdateValue: PropTypes.func.isRequired,
    trls: PropTypes.object.isRequired,
};

Resume.trls = {
    emptyTitle: 'resume.title.empty',
    updated: 'resumeList.updated.label',
    updatedManual: 'resumeList.updated.label.manual',
    notFinishedText: 'resumeList.status.notFinished.text',
    blockedText: 'resumeList.status.blocked.text',
    isPreparingByExpert: 'resumeList.resume.status.isPreparingByExpert',
};

export default connect(
    (state, ownProps) => {
        const { statistics, recommendation, recommendationDetails } =
            state.applicantResumesStatistics?.resumes?.[ownProps.resume._attributes.id] || {};

        return {
            statistics,
            recommendation,
            recommendationDetails,
        };
    },
    {
        setToUpdateValue: resumesSetToUpdateValueAction,
    }
)(translation(Resume));
