/* eslint-disable import/prefer-default-export */
import { AxiosError } from 'axios';
import { push } from 'connected-react-router';
import { Dispatch, AnyAction } from 'redux';

import Analytics from '@hh.ru/analytics-js';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import { AddNotification } from 'src/components/Notifications/Provider/types';
import { MaxLimitNotification } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/components/MaxLimitNotification';
import { MAX_RESUME_LIMIT_EXCEEDED } from 'src/utils/constants/resume';
import fetcher from 'src/utils/fetcher';

export const cloneResume =
    (params: { resume: string }, addNotification: AddNotification) =>
    (dispatch: Dispatch<AnyAction>): Promise<void> =>
        fetcher
            .post('/applicant/resumes/clone', null, { params })
            .then(({ data }) => {
                Analytics.sendEvent('applicant', 'resume-started', 'double');
                dispatch(push(data.url));
            })
            .catch((error: AxiosError<{ error: string }>) => {
                if (error.response && error.response.data && error.response.data.error === MAX_RESUME_LIMIT_EXCEEDED) {
                    addNotification(MaxLimitNotification, { uniqueType: true });
                } else {
                    defaultRequestErrorHandler(error, addNotification);
                }
            });
