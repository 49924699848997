import { EVERYONE, CLIENTS, BLACKLIST } from 'src/models/resume/resumeAccess.types';

const ALLOWED_ACCESS_TYPES = [EVERYONE, CLIENTS, BLACKLIST];
const TIME_DELTA_TO_SHOW_PROMO = 1000 * 60 * 60 * 48; // 48 hours
const NOW = Date.now();

export default (publishedResumes) =>
    !!publishedResumes
        .filter(({ _attributes }) => !_attributes.renewal)
        .filter(({ accessType }) => ALLOWED_ACCESS_TYPES.includes(accessType[0].string))
        .find(({ _attributes }) => NOW - new Date(_attributes.moderated).getTime() < TIME_DELTA_TO_SHOW_PROMO);
