/* eslint-disable import/prefer-default-export */
import { FC } from 'react';

import { useBreakpoint } from '@hh.ru/magritte-ui';

import { ApplicantResumeItem } from 'src/models/applicantResumes';

import { DesktopResumeActions } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/components/ResumeActions/DesktopResumeActions';
import { MobileResumeActions } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/components/ResumeActions/MobileResumeActions';

type Props = {
    resume: ApplicantResumeItem;
    onUpdate: (time: number) => void;
    onTimeChanged: (time: number) => void;
    isAutoRenewalRecommended: boolean;
};

export const ResumeActions: FC<Props> = (props) => {
    const { isMobile } = useBreakpoint();

    return isMobile ? <MobileResumeActions {...props} /> : <DesktopResumeActions {...props} />;
};
