/* eslint-disable import/prefer-default-export */
import { useEffect, useRef } from 'react';

import resumeActionLinkButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/resume_list/resume_action_link_button_click';
import resumeRecommendationButtonClick, {
    Type,
} from '@hh.ru/analytics-js-events/build/xhh/applicant/resume_list/resume_recommendation_button_click';
import resumeRecommendationElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/resume_list/resume_recommendation_element_shown';
import { Button } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import { format } from 'bloko/common/trl';

import paths from 'src/app/routePaths';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ResumeUpdate from 'src/components/ResumeUpdate';
import SuitableVacanciesText from 'src/components/SuitableVacanciesText';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { ApplicantResumeItem } from 'src/models/applicantResumes';
import { Recommendations } from 'src/models/resume/resumeCommon.types';
import { BLOCKED } from 'src/utils/constants/resumeStatuses';

import { RecommendationButton } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/components/ResumeButtons/RecommendationButton';
import { RenewalButton } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/components/ResumeButtons/RenewalButton';
import { UpdateButton } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/components/ResumeButtons/UpdateButton';

import styles from './styles.less';

const TrlKeys = {
    actionEditResume: 'resumeList.recommendations.action.edit',
    verifyPhoneSubmit: 'resumes.phoneVerification.resumeToAuth.submitPhone',
    suitableVacancies: 'resumeList.actionline.suitableVacancies',
    actionCorrectResume: 'resumeList.recommendations.action.correct',
    actionChangeVisibility: 'resumeList.recommendations.action.visibility',
    undergoSkillsSurveyBase: 'resumeList.recommendations.text.startSurvey.base',
};

type Props = {
    resume: ApplicantResumeItem;
    isAutoRenewalRecommended: boolean;
    onUpdate: (time: number) => void;
    onTimeChanged: (time: number) => void;
};

export const ResumeButtons = translation<Props>(
    ({
        trls,
        resume,
        onUpdate,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onTimeChanged = () => {},
        isAutoRenewalRecommended,
    }) => {
        const recommendationRef = useRef<HTMLButtonElement | null>(null);

        const { _attributes, humanDatesRules, toUpdate, renewalTime } = resume;
        const { status, hash, isSearchable, id } = _attributes;

        const recommendation = useSelector(
            ({ applicantResumesStatistics }) => applicantResumesStatistics?.resumes?.[id]?.recommendation
        );
        const recommendationDetails = useSelector(
            ({ applicantResumesStatistics }) => applicantResumesStatistics?.resumes?.[id]?.recommendationDetails
        );

        const url = useSelector(({ request }) => request.url);
        const suitableVacanciesCount = useSelector(
            ({ applicantSuitableVacancyByResume }) => applicantSuitableVacancyByResume[hash] || 0
        );

        const resumeId = parseInt(id, 10);

        useEffect(() => {
            if (recommendationRef.current) {
                resumeRecommendationElementShown(recommendationRef.current, {
                    type: recommendation as Type,
                    resumeId,
                    surveyProfession: recommendationDetails?.surveyProfession || '',
                });
            }
        }, [recommendation, recommendationDetails, id, resumeId]);

        const sendRecommendationClickAnalytics = () => {
            resumeRecommendationButtonClick({
                type: recommendation as Type,
                resumeId,
                surveyProfession: recommendationDetails?.surveyProfession || '',
            });
        };

        const handleSuitableVacanciesClick = () => {
            if (recommendation === Recommendations.Respond) {
                resumeRecommendationButtonClick({
                    type: recommendation,
                    resumeId,
                    surveyProfession: recommendationDetails?.surveyProfession || '',
                });
            } else {
                resumeActionLinkButtonClick({ buttonName: 'resume_vacancy_search_list', resumeId });
            }
        };

        return (
            <div className={styles.root}>
                {recommendation === Recommendations.NeedSkillSurvey && (
                    <Button
                        Element={Link}
                        ref={recommendationRef}
                        size="small"
                        mode="primary"
                        style="accent"
                        onClick={sendRecommendationClickAnalytics}
                        data-qa="undergo-skill-survey"
                        to={
                            `/skills_survey/applicant?surveyProfession=` +
                            `${recommendationDetails?.surveyProfession || ''}&source=${id}`
                        }
                    >
                        {format(trls[TrlKeys.undergoSkillsSurveyBase], {
                            '{0}': recommendationDetails?.translations?.resumeStartSurveyProfession || '',
                        })}
                    </Button>
                )}

                {recommendation === Recommendations.NeedVerifiedPhone && (
                    <Button
                        Element={Link}
                        ref={recommendationRef}
                        size="small"
                        mode="primary"
                        style="negative"
                        onClick={sendRecommendationClickAnalytics}
                        data-qa="resume-phone-not-verified"
                        to={`/applicant/phone_verification?resume=${hash}`}
                    >
                        {trls[TrlKeys.verifyPhoneSubmit]}
                    </Button>
                )}

                {(recommendation === Recommendations.EditResume || recommendation === Recommendations.OnModeration) && (
                    <Button
                        Element={Link}
                        ref={recommendationRef}
                        size="small"
                        mode="primary"
                        style="accent"
                        onClick={sendRecommendationClickAnalytics}
                        data-qa="resume-phone-not-verified"
                        to={
                            isSearchable || status === BLOCKED
                                ? `/resume/${hash}`
                                : `/applicant/resumes/short?resume=${hash}`
                        }
                    >
                        {trls[TrlKeys.actionEditResume]}
                    </Button>
                )}

                {recommendation === Recommendations.ChangeVisibility && (
                    <Button
                        ref={recommendationRef}
                        Element={Link}
                        size="small"
                        mode="primary"
                        style="accent"
                        onClick={sendRecommendationClickAnalytics}
                        data-qa={`resume-recommendations__button_${recommendation}`}
                        to={`/applicant/resumes/edit/visibility?backUrl=${url}&resume=${hash}`}
                    >
                        {trls[TrlKeys.actionChangeVisibility]}
                    </Button>
                )}

                {recommendation === Recommendations.CorrectResume && (
                    <Button
                        ref={recommendationRef}
                        Element={Link}
                        size="small"
                        mode="primary"
                        style="negative"
                        onClick={sendRecommendationClickAnalytics}
                        data-qa="resume-status-blocked"
                        to={`/applicant/resumes/completion?resume=${hash}`}
                    >
                        {trls[TrlKeys.actionCorrectResume]}
                    </Button>
                )}

                {recommendation === Recommendations.Respond && (
                    <ResumeUpdate
                        isButton
                        hash={hash}
                        onUpdate={onUpdate}
                        timeLeft={toUpdate.value * 1000}
                        renewalTime={renewalTime}
                        onTimeChanged={onTimeChanged}
                        updateInterval={_attributes.update_timeout}
                        RenderComponent={RenewalButton}
                        humanDatesRules={humanDatesRules}
                        isAutoRenewalRecommended={isAutoRenewalRecommended}
                    />
                )}

                {recommendation === Recommendations.UpdateResume && (
                    <ResumeUpdate
                        hash={hash}
                        timeLeft={toUpdate.value * 1000}
                        onUpdate={onUpdate}
                        renewalTime={renewalTime}
                        onTimeChanged={onTimeChanged}
                        updateInterval={_attributes.update_timeout}
                        humanDatesRules={humanDatesRules}
                        RenderComponent={UpdateButton}
                    />
                )}

                <Button
                    ref={recommendationRef}
                    Element={Link}
                    size="small"
                    mode="secondary"
                    style="accent"
                    onClick={handleSuitableVacanciesClick}
                    data-qa={`resume-recommendations__button_${recommendation || ''}`}
                    to={`${paths.vacancySearch}?resume=${hash}&from=resumelist`}
                >
                    <SuitableVacanciesText count={suitableVacanciesCount} />
                </Button>

                <RecommendationButton resumeHash={hash} />
            </div>
        );
    }
);
