import { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import VSpacing from 'bloko/blocks/vSpacing';

import { ApplicantServices } from 'src/components/ApplicantServices/common';
import CareerPlatformBanner from 'src/components/CareerPlatformBanner/index';
import ContainerForMicroFrontend from 'src/components/CodeInjector/ContainerForMicroFrontend';
import useExperiment from 'src/hooks/useExperiment';
import { useIsHeadHunterPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';
import { CareerBannerPosition, CareerLinksKeys } from 'src/models/careerPlatformState';

import FindJobPromo from 'src/pages/ApplicantResumes/components/FindJobPromo';
import Resume from 'src/pages/ApplicantResumes/components/Resume';
import shouldShowFindJobPromo from 'src/pages/ApplicantResumes/components/shouldShowFindJobPromo';

const ApplicantResumes = ({ publishedResumes, unpublishedResumes, hasRenewalService }) => {
    const isMagritteExp = useExperiment('magritte_resume_list');
    const latestResumeHash = useSelector((state) => state.latestResumeHash);
    const linksState = useSelector((state) => state.careerPlatformLinks);
    const hasCodePracticeBanner = useSelector((state) => !!state.microFrontends.codePracticeBanner);
    const isHeadHunterPlatform = useIsHeadHunterPlatform();
    const showFindJobPromo = isHeadHunterPlatform && hasRenewalService && shouldShowFindJobPromo(publishedResumes);

    return (
        <Fragment>
            {publishedResumes.concat(unpublishedResumes).map((resume, index, resumeList) => (
                <Fragment key={resume._attributes.id}>
                    <Resume resume={resume} />
                    {showFindJobPromo && index === 0 && <FindJobPromo />}
                    {isMagritteExp &&
                        latestResumeHash === resume._attributes.hash &&
                        !(hasCodePracticeBanner && resumeList.length - 1 === index) && (
                            <>
                                <ContainerForMicroFrontend
                                    place={`careerPlatformBannerByResumeHash-${latestResumeHash}`}
                                />

                                <VSpacing base={6} />
                            </>
                        )}
                    {!isMagritteExp &&
                        linksState?.resume_hash === resume._attributes.hash &&
                        !(hasCodePracticeBanner && resumeList.length - 1 === index) && (
                            <>
                                <CareerPlatformBanner
                                    position={CareerBannerPosition.Profile}
                                    destination={CareerLinksKeys.Calc}
                                />

                                <VSpacing base={6} />
                            </>
                        )}
                </Fragment>
            ))}
        </Fragment>
    );
};

ApplicantResumes.propTypes = {
    publishedResumes: PropTypes.array,
    unpublishedResumes: PropTypes.array,
    trls: PropTypes.object,
    hasRenewalService: PropTypes.bool,
};

export default connect((state) => ({
    publishedResumes: state.applicantResumes.filter(({ _attributes }) => _attributes.isSearchable),
    unpublishedResumes: state.applicantResumes.filter(({ _attributes }) => !_attributes.isSearchable),
    hasRenewalService: state.applicantPaymentServices.includes(ApplicantServices.ResumeRenewal),
}))(ApplicantResumes);
