/* eslint-disable import/prefer-default-export */
import formatDate from 'date-fns/format';

import { Card, Text } from '@hh.ru/magritte-ui';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useIsHeadHunterPlatform } from 'src/hooks/usePlatform';
import { ApplicantResumeItem } from 'src/models/applicantResumes';
import { RecommendationDetails } from 'src/models/applicantResumesStatistics';
import { Recommendations } from 'src/models/resume/resumeCommon.types';
import { useBotUpdateResumeContext } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/context/botUpdateResume';

import { ResumeAudit } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/components/ResumeRecommendations/ResumeAudit';
import { ResumeRecommendationText } from 'src/pages/ApplicantResumes/components/redesign/ApplicantResumes/components/ResumeRecommendations/ResumeRecommendationText';

const TrlKeys = {
    actionEditResume: 'resumeList.recommendations.action.edit',
    verifyPhoneSubmit: 'resumes.phoneVerification.resumeToAuth.submitPhone',
    suitableVacancies: 'resumeList.actionline.suitableVacancies',
    actionCorrectResume: 'resumeList.recommendations.action.correct',
    successUpdateMessage: 'botUpdateResume.successUpdateMessage',
    actionChangeVisibility: 'resumeList.recommendations.action.visibility',
    undergoSkillsSurveyBase: 'resumeList.recommendations.text.startSurvey.base',
};

type Props = {
    resume: ApplicantResumeItem;
    recommendation?: Recommendations;
    recommendationDetails?: RecommendationDetails;
};

export const ResumeRecommendations = translation<Props>(({ resume, recommendation, recommendationDetails, trls }) => {
    const resumeHash = resume?._attributes?.hash;
    const isHeadHunter = useIsHeadHunterPlatform();

    const { successUpdate, canUpdateResumeByBot } = useBotUpdateResumeContext();

    if (!recommendation || !isHeadHunter) {
        return <ResumeAudit resumeHash={resumeHash} />;
    }

    const renderRecommendationsText = () => {
        if (canUpdateResumeByBot && successUpdate) {
            return (
                <Text style="secondary" typography="custom-3-medium" suppressHydrationWarning>
                    {format(trls[TrlKeys.successUpdateMessage], {
                        '{0}': formatDate(new Date(), 'HH:mm'),
                    })}
                </Text>
            );
        }

        return (
            <Text style="secondary" typography="custom-3-medium" suppressHydrationWarning>
                <ResumeRecommendationText
                    recommendation={recommendation}
                    recommendationDetails={recommendationDetails}
                />
            </Text>
        );
    };

    return (
        <>
            <Card stretched style="neutral" padding={16} borderRadius={16}>
                {renderRecommendationsText()}
            </Card>
            <ResumeAudit resumeHash={resumeHash} />
        </>
    );
});
